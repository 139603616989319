.activities {
    background-color: var(--quartenary-color);
}

.activities-pagination {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.activity-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: var(--font-xs);
    margin-bottom: 20px;
}

.activities-pagination li {
    font-size: 0.8rem;
    color: var(--primary-color);
    flex-basis: 24.5%;
    text-align: center;
    padding: 10px 0;
}

.activities-pagination li:hover,
.activities-pagination .selected-activity {
    background-color: var(--primary-color);
    color: var(--quartenary-color);
    cursor: pointer;
    transition: 0.1s;
    border-radius: 20px;
}

.activity-desc {
    margin-top: 20px;
    color: var(--dark-color);
    padding: 10px 10px 10px 15px;
    border: 2px solid var(--primary-color);
    border-radius: 20px;
    display: block;
}

@media screen and (min-width: 768px) {
    .activities-pagination li {
        font-size: 1rem;
        flex-basis: 15%;
        padding: 40px 0;
    }

    .activity-icon {
        font-size: 3rem;
    }

    .activity-desc {
        max-width: 90%;
        margin: 20px auto;
        font-size: 1.3rem;
    }
}
