* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button {
    border: none;
    cursor: pointer;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
}

