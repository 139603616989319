:root {
    /*colors*/
    --primary-color: #204c91;
    --secondary-color: #0077b6;
    --tertiary-color: #00a8e8;
    --quartenary-color: #e6edf4;
    --fifth-color: #dce3ea;
    --dark-color: #03045e;
    --bright-color: white;
    /*font-sizes*/
    --font-xxl: 4rem;
    --font-xl: 3rem;
    --font-l: 2.5rem;
    --font-n: 2rem;
    --font-s: 1.3rem;
    --font-xs: 1rem;
    --font-xxs: 0.8rem;
}
