.donate-hero {
    position: relative;
    width: 100vw;
    height: 90vh;
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.donate-hero h2 {
    color: white;
}

.donate-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../assets/images/donate-mobile.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    filter: brightness(60%);
}

.content {
    position: relative;
    font-family: 'Monserrat', sans-serif;
    color: white;
    text-align: center;
}
.title {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 0;
}
.subtitle {
    font-size: 2rem;
    font-weight: 200;
    margin-top: 1rem;
}

.donate-hero button {
    background-color: var(--dark-color);
    color: white;
    margin-top: 5rem;
    padding: 0.9375rem 1.875rem;
    font-family: 'Monserrat', sans-serif;
    font-size: var(--font-xs);
    font-weight: 200;
    cursor: pointer;
    border-radius: 10px;
}

.donate-hero p {
    border: 2px solid white;
    background-color: var(--quartenary-color);
    color: var(--dark-color);
    max-width: 40%;
    font-size: var(--font-xs);
    margin: 0 auto;
    line-height: var(--font-s);
}

@media screen and (min-width: 768px) {
    .donate-hero::before {
        background: url(../assets/images/donate-hero.png);
    }
}
