header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--quartenary-color);
    padding: 0 5%;
    height: 10vh;
    z-index: 100;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    max-width: 100%;
    overflow-x: hidden;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.logo {
    width: 70px;
    height: 70px;
}
