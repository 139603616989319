.mission-vision h3 {
    color: var(--primary-color);
    font-size: var(--font-s);
    font-weight: 900;
    text-align: center;
}

.mission-vision-img img {
    max-width: 300px;
    max-height: 250px;
    border-radius: 20px;
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.mission-vision-desc {
    padding: 10px 10px 10px 15px;
    border: 2px solid var(--primary-color);
    border-radius: 20px;
}

.mission-vision-desc p,
.mission-vision-desc ol {
    margin: 15px 0;
    color: var(--dark-color);
}

.mission-vision-desc ol li {
    margin-bottom: 5px;
}

.mission-vision-desc ol li::before {
    content: '\2022';
    color: var(--dark-color);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -0.8em;
}

.mission-vision-img img:last-child {
    display: none;
}

@media screen and (min-width: 768px) {
    .mission-vision-img {
        display: flex;
        justify-content: space-between;
    }

    .mission-vision-img img {
        flex-basis: 45%;
        margin: 15px 0;
    }
}

@media screen and (min-width: 900px) {
    .mission-vision {
        display: flex;
        gap: 20px;
    }

    .mission-vision-img img:last-child {
        display: block;
    }

    .mission-vision-img img:first-child {
        margin-top: 0;
    }

    .mission-vision-img {
        display: block;
    }
}
