.banner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: var(--bright-color);
    height: 100vh;
}

.org-intro {
    animation-duration: 2s;
    animation-name: animate-bottom;
}

.org-name {
    text-align: center;
}

.banner-btns {
    margin-top: 4rem;
}

.banner-btns button {
    display: block;
    margin-left: 30%;
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    font-size: var(--font-xs);
    border-radius: 10px;
    border: 2px solid var(--quartenary-color);
}

.learn-more-btn,
.donate-btn {
    background-color: var(--quartenary-color);
    color: var(--dark-color);
}

.contact-btn {
    background-color: transparent;
    color: var(--quartenary-color);
}

.banner-btns .learn-more-btn:hover,
.banner-btns .donate-btn:hover {
    background-color: transparent;
    color: var(--quartenary-color);
}

.banner-btns .contact-btn:hover {
    background-color: var(--quartenary-color);
    color: var(--primary-color);
}

.banner img {
    display: none;
}

.banner {
    background: var(--primary-color);
    background: linear-gradient(
        90deg,
        var(--dark-color) 0%,
        var(--primary-color) 43%,
        var(--secondary-color) 100%
    );
    width: 100%;
}

@media screen and (min-width: 768px) {
    .org-name {
        display: flex;
        align-items: center;
    }

    .org-name p {
        margin-left: 10px;
        font-size: 1.5rem;
    }

    .banner h1 {
        font-size: 4rem;
        display: inline;
        border-right: 2px solid var(--bright-color);
        padding-right: 10px;
        margin-left: 20px;
    }

    .banner-btns {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .banner-btns button {
        font-size: 1.3rem;
        padding: 20px;
        margin-left: 20px;
    }
}

@media screen and (min-width: 900px) {
    /*Banner section*/
    .banner img {
        display: block;
        animation-duration: 2s;
        animation-name: animate-top;
    }
}
