.org-contact-links {
    display: flex;
    flex-flow: column;
    background-color: var(--dark-color);
    color: var(--bright-color);
    padding: 20px 5%;
}

.org-contact-links a {
    line-height: 1.5rem;
    font-size: var(--font-xs);
    color: var(--bright-color);
}

.social-links {
    margin-top: 10px;
}

.social {
    font-size: var(--font-s);
}

.social:first-of-type {
    margin-right: 15px;
}

.social-links span {
    color: grey;
    font-size: var(--font-xxs);
}

.contact-info a {
    margin-left: 5px;
}

@media screen and (min-width: 768px) {
    .org-contact-links {
        flex-flow: row;
        justify-content: space-between;
    }

    .social-links {
        margin-top: 0px;
    }
}
