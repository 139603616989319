.introduction {
    text-align: center;
    padding-top: 40px;
}

.introduction p {
    padding: 20px 0;
    font-size: var(--font-s);
    color: var(--dark-color);
    text-align: left;
}

@media screen and (min-width: 768px) {
    .introduction {
        display: flex;
    }

    .introduction p {
        margin-right: 50px;
    }
}
