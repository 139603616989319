.about {
    background-color: var(--dark-color);
    padding-top: 10vh;
}

.about h1 {
    color: var(--quartenary-color);
    text-align: center;
    border-right: none;
}

.about-desc {
    margin-top: 20px;
}

.about-desc p {
    color: var(--quartenary-color);
    font-size: var(--font-xs);
    line-height: var(--font-s);
    border: 1px solid var(--quartenary-color);
    padding: 10px 10px 10px 15px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.about-desc img {
    display: block;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    /*About section*/
    .about-desc {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .about-desc p {
        margin-right: 30px;
    }
}
