.project-area {
    margin-top: 10vh;
    min-height: 90vh;
}

.project-area h3 {
    text-align: center;
    font-size: var(--font-n);
    color: var(--dark-color);
    padding: 30px 10px;
}

.project-image-container {
    margin: 20px 0;
}

.project-image-container figcaption {
    color: grey;
    margin-top: 10px;
}

.project-image-container figure {
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
}

.project-image-container img {
    display: block;
    border-radius: 20px;
    margin: 0 auto;
    width: 100%;
}

.project-description {
    margin-top: 20px;
    color: var(--dark-color);
    padding: 10px 10px 10px 15px;
    border: 2px solid var(--primary-color);
    border-radius: 20px;
    display: block;
}

.project-description p {
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .project-area {
        display: flex;
        max-width: 90%;
        margin: 40px auto;
    }

    .project-image-container {
        display: flex;
    }

    .project-image-container figure {
        width: 40%;
    }

    .project-description {
        margin-top: 40px auto;
        font-size: 1.3rem;
    }
}
