.work-area {
    margin-top: 10vh;
    min-height: 90vh;
}

.org-contact-links {
    margin-top: auto;
}

.work-area h2 {
    text-align: center;
    background-color: var(--dark-color);
    color: var(--quartenary-color);
}

.work-list li {
    padding: 10px 0;
}

.work-list li a {
    color: var(--dark-color);
}

.work-list li span {
    margin-left: 20px;
}

.work-list li li {
    margin-left: 50px;
}
