*:hover {
    transition: 0.3s;
}

html,
body {
    overflow-x: hidden; /*no horizontal scrolling*/
}

body {
    font-family: 'Open Sans', sans-serif;
    background-color: var(--quartenary-color);
    width: 100%;
}

/* align element */
.center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-container {
    max-width: 90%;
    margin: 0 auto;
}

.padded-container {
    padding: 40px 0;
}
/* end of align elements*/

/*headings*/
h1 {
    font-size: var(--font-xl);
    font-weight: 900;
}

h2 {
    color: var(--primary-color);
    font-size: var(--font-l);
    font-weight: 800;
    text-align: center;
}

h4 {
    font-size: var(--font-n);
    text-align: center;
}

/*animations*/
@keyframes animate-in {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}

@keyframes animate-bottom {
    from {
        margin-bottom: 100%;
    }

    to {
        margin-top: 0%;
    }
}

@keyframes animate-top {
    from {
        margin-top: 100%;
    }

    to {
        margin-bottom: 0%;
    }
}
