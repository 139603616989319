.donors div {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.donors img {
    width: 150px;
    margin-top: 20px;
}

@media screen and (min-width: 768px) {
    .donors img {
        margin-top: 30px;
    }
}
