.media iframe {
    display: block;
    width: 300px;
    height: 300px;
    margin: 0 auto;
}

.media iframe:last-of-type {
    margin-top: 20px;
}

@media screen and (min-width: 768px) {
    .media-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

@media screen and (min-width: 900px) {
    .media iframe {
        width: 430px;
    }
}
