.our-team h2 {
    color: var(--primary-color);
    font-size: var(--font-n);
    font-weight: bolder;
    text-align: center;
}

.our-team ul {
    margin-top: 40px;
}

.our-team li {
    margin-bottom: 50px;
}

.member-card {
    background-color: var(--dark-color);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
}

.member-card img {
    display: block;
    margin: -25px auto 0;
    width: 140px;
    height: 140px;
}

.member-card .icon {
    display: block;
    margin: -25px auto 0;
    width: 140px;
    height: 140px;
    color: white;
}

.our-team h4 {
    color: var(--primary-color);
    font-weight: 700;
    font-size: var(--font-s);
}

.our-team h3,
.our-team h4 {
    text-align: left;
    color: var(--dark-color);
    padding-top: 10px;
}

.our-team p {
    font-size: var(--font-xs);
}

.member-card-desc {
    background-color: var(--bright-color);
    padding: 0 10px 10px 15px;
    color: var(--dark-color);
    height: 250px;
    overflow-y: scroll;
}

.member-card-desc p {
    margin-top: 10px;
    font-weight: bold;
}

@media screen and (min-width: 768px) {
    .our-team ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .our-team ul li {
        flex-basis: 30%;
    }
}
