#portal .container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
}

.container .modal {
    z-index: 1;
    position: relative;
    animation: animate 0.3s;
}

@keyframes animate {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}
