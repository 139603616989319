nav {
    display: flex;
    align-items: center;
}

.nav-links {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: fixed;
    left: -100%;
    top: 10vh;
    transition: 0.3s;
    background-color: var(--quartenary-color);
    width: 100%;
}

.nav-links.open-menu {
    left: 0;
}

.nav-links li {
    border-bottom: 1px solid var(--secondary-color);
    width: 100%;
    text-align: center;
}

.nav-links a {
    color: var(--primary-color);
    font-weight: bolder;
    font-size: var(--font-xs);
    line-height: var(--font-l);
    transition: 0.3s;
}

.menu {
    cursor: pointer;
}

.icon {
    color: var(--dark-color);
}

.nav-links li a:hover {
    color: #1c2c3b;
    border-bottom: 2px solid var(--dark-color);
    font-weight: bold;
}

@media screen and (min-width: 768px) {
    .nav-links a {
        font-size: var(--font-s);
    }
}

@media screen and (min-width: 900px) {
    nav {
        flex-basis: 60%;
    }

    .nav-links {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        position: static;
        background: transparent;
    }

    .nav-links li {
        display: block;
        border: none;
    }

    .nav-links li:last-child a {
        background-color: var(--dark-color);
        border-radius: 20px;
        padding: 10px 20px;
        color: var(--bright-color);

        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .nav-links li:last-child a:hover {
        color: var(--primary-color);
        background-color: transparent;
        border: 2px solid var(--primary-color);
        box-shadow: none;
        cursor: pointer;
        font-weight: bold;
    }

    .nav-links a {
        font-size: var(--font-s);
    }

    .menu {
        display: none;
    }
}
