.stats {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    max-width: 90%;
    border-radius: 30px;
    background-color: var(--dark-color);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.58);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.58);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.58);
}

.stat-info {
    color: var(--bright-color);
    flex-basis: 80%;
    text-align: center;
    padding: 20px;
}

.stat-info span {
    font-size: var(--font-l);
    font-weight: 900;
}

@media screen and (min-width: 768px) {
    .stats {
        justify-content: space-evenly;
        font-size: 1rem;
    }

    .stat-info {
        flex-basis: 20%;
    }
}
